import * as React from "react";

import Layout from "../../../components/layout/layout";
import Seo from "../../../components/seo";
import Inscription from "../../../components/tasem-test/inscription";

const Index = () => {
  return (
    <>
      <Seo
        title="Inscription au TASEM"
        description="L’inscription au test TASEM se déroule en ligne. Cette page référencie tout ce qu’il faut savoir sur les modalités d’inscription au TASEM : le règlement du test, son prix et le dispositif handicap mis en place."
      />
      <Layout>
        <Inscription />  
      </Layout>
    </>
  )
}

export default Index;
