import React from "react";
import { useTranslation } from 'react-i18next';

import Ready from "../ready/ready";
import Products from "../product/products";
import TopBanner from "../top_banner/product_top_banner";
import Work from "../work/work";
import FAQS from "../faqs/faqs";
import SecondaryHeader from "../secondary-header";
import { secondaryMenu } from "../../constants";

const Inscription = () => {
  const {t} = useTranslation();
  const products = [
    {
      title: `${t('regulations')}`,
      href: "/règlement",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Window.svg",
      description: `${t('everything_you_need_to_know')}`
    },
    {
      title: `${t('price')}`,
      href: "/prix",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/extension_icon.svg",
      description: `${t('two_possible_prices_depending')}`
    },
    {
      title: `${t('disability_device')}`,
      href: "/dispositif-handicap",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/shield_icon_with_bg.svg",
      description: `${t('procedures_put_in_place')}`
    },
    {
      title: `${t('d_day')}`,
      href: "/le-jour-j",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/book_icon_with_bg.svg",
      description: `${t('everything_you_need_to_d_day')}`
    },
    {
      title: `${t('schools')}`,
      href: "/schools",
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/analyse_icon_with_bg.svg",
      description: `${t('list_of_higher_education')}`
    }
  ];

  const works = [
    {
      title: `${t('before_my_exam')}`,
      description: `${t('the_arrangements_to_take_to_take_the_test')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/person-sitting-on-mobile.svg",
      button: {
        text: `${t('see_more')}`,
        href: "https://mereos.eu/tests/tesam-test/acces/"
      }
    },
    {
      title: `${t('data_protection')}`,
      description: `${t('commitments_relating')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/laptop-locked.svg",
      button: {
        text: `${t('see_more')}`,
        href: "https://mereos.eu/protection-des-donn%C3%A9es"
      }
    }
  ];

  const supportFAQS = [
    {
      title: "Why don't you count usage/ active users/ tests taken as opposed to licensed users?",
      description: "Your success is our success. We want you to sell as much as humanly possible- if you win first, we can also win. As such, we need our incentives to be aligned. We want you to build the best possible product. We firmly believe that the more active your users are… the more value they will derive from the product… the more likely they are to stick around, and to tell their friends. If we make it about usage, you are explicitly disincentivized  to build a great product that people use.",
    },
    {
      title: "Can I buy just one product or feature / Do I have to buy the whole product suite?",
      description: `${t('no_in_accordance_with_cnil')}`,
    },
    {
      title: "Can I build a Proof-of-Concept before I buy?",
      description: `${t('no_mereos_does_not_monitor_candidates')}`,
    },
    {
      title: "Is Learnosity for me?",
      description: `${t('subtle_movements_such_as_those_mentioned')}`,
    },
    {
      title: "What’s your renewal process?",
      description: `${t('no_the_mereos_algorithm_only_provides')}`,
    },
    {
      title: "What is a user?",
      description: `${t('no_the_mereos_algorithm_only_provides')}`,
    },
  ];

  return (
    <div className={`font-sans`}>
      <SecondaryHeader
        menu={secondaryMenu}
      />
      <TopBanner
        pageTitle={t('registration')}
        title={t('registration_for_the_tasem')}
        description={t('registration_for_the_tase_test')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/desktop-2.svg"
        page={"platform"}
        leftButton={{
          text: `${t('register')}`,
          href: "https://registration.mereos.eu/candidature/?id=1",
        }}
        rightButton={{
          text: `Le test >`,
          href: "/tests/tasem-test/",
        }}
      />

      <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
        <div className="flex justify-center items-center md:justify-between  w-full mx-auto my-10 relative">
          <img
            alt="right star "
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/right_star_icon.svg"
            className="hidden  md:block absolute -left-10 -top-20"
          />
        </div>
        <Products
          title={t('registration')}
          description={t('find_out_how_to_register')}
          products={products}
        />
      </div>
      <div className="lg:my-32">
        <Work works={works}/>
      </div>

      <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
        <FAQS
          title={t('how_to_register_for_the_tasem_test')}
          faqsType='Support FAQs'
          data={supportFAQS}
        />
        <div className=" mx-auto my-20">
          <Ready
            title={t('ready_to_test_mereos')}
            description={t('discover_our_ready_to_use_solutions')}
            leftButton={{
              text: `${t('register')}`,
              href: "https://registration.mereos.eu/candidature/?id=1"
            }}
            rightButton={{
              text: t('learn_more'),
              href: ""
            }}
            image="https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Inscription;
